<template>
  <div>
    <div v-if="!id && !getCheckMaxCalendars()">
      <b-flix-p>
        <b-flix-h2>
          {{ $t('message.createNew', { name: $tc('message.calendar', 1) }) }}
        </b-flix-h2>
      </b-flix-p>
      <div class="flix-alert flix-alert-danger">
        <span>
          {{ $store.getters.assistents.length }} /
          {{ $store.getters.assistents.length }}
          {{ $tc('message.calendar', $store.getters.assistents.length) }}
          <br />
          <a
            :href="getURL() + '/prices'"
            target="_blank"
            class="flix-html-a flix-text-danger"
            style="font-weight: bold"
            >{{ $tc('message.price', 2) }}</a
          >
          |
          <a
            :href="getURL() + '/support/chat'"
            target="_blank"
            class="flix-html-a flix-text-danger"
            style="font-weight: bold"
            >{{ $t('message.supportChat') }}</a
          >
        </span>
      </div>
    </div>
    <div v-else>
      <div class="flix-text-right" v-if="!id">
        <b-flix-alert variant="info" style="display: inline-flex;">
          {{ $store.getters.assistents.length }} /
          {{ $store.getters.user.max_calendar }}
          {{ $tc('message.calendar', $store.getters.assistents.length) }}
        </b-flix-alert>
      </div>
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'checkMaxCalendars',
  components: {},
  props: {
    id: {
      type: [String, Number]
    }
  },
  data() {
    return {}
  },
  computed: {},
  mounted() {},
  methods: {
    getURL() {
      return (
        'https://bookingflix.' +
        (this.$i18n.locale === 'en' ? 'net' : this.$i18n.locale)
      )
    },
    getCheckMaxCalendars() {
      const currentCalendars = this.$store.getters.assistents.length * 1
      let maxCalendars = this.$store.getters.user.max_calendar * 1

      if (this.$store.getters.user.membership !== 'premium') {
        maxCalendars = 1
      }

      if (currentCalendars >= maxCalendars) {
        return false
      }
      return true
    }
  }
}
</script>
<style lang="sass" scoped></style>
